@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;700&family=Montserrat&display=swap');

$background: rgb(10, 25, 47);
$background-light: rgb(23, 42, 69);
$background-dark: rgb(2, 12, 27);
$tag-background: rgb(60, 73, 102);

$primary: rgb(100, 255, 218);

$font-primary: rgb(230, 241, 255);
$font-secondary-light: rgb(204, 214, 246);
$font-secondary: rgb(136, 146, 176);

$red: #e04c4c;
$green: #84cc6d;

$grey-line: rgb(168, 178, 209);
$grey-line-dark: rgb(45, 57, 82);

$animate: cubic-bezier(0.645, 0.045, 0.355, 1);

$sans-serif: 'Montserrat', sans-serif;
$monospace: 'Fira Code', monospace;