@import '../vars';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 0;
    padding-top: 20px;
    text-align: center;
    color: $primary;
  }

  .content {
    background-color: desaturate(lighten($background, $amount: 5), 30);
    width: 60%;
    padding: 20px;
    font-family: $monospace;
    color: $font-secondary;
    ul {
      list-style: disc;
      padding-left: 20px;
    }
  }
}