@import 'vars';

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-family: $sans-serif;
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.label {
  color: $font-secondary;
  font-size: 12px;
}

.container {
  background: $background;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 400px;
}
